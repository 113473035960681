// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `process-task-appointment-forms-widget {
  display: block;
}

process-task-appointment-forms-widget-form {
  display: block;
}

.process-task-appointment-forms-widget--ButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.process-task-appointment-forms-widget--ButtonContainer > * {
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/operationsComponents/process-task-appointment-forms-widget/process-task-appointment-forms-widget.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["process-task-appointment-forms-widget {\n  display: block;\n}\n\nprocess-task-appointment-forms-widget-form {\n  display: block;\n}\n\n.process-task-appointment-forms-widget--ButtonContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 30px;\n}\n\n.process-task-appointment-forms-widget--ButtonContainer > * {\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
